import React from 'react';

interface TermsModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const TermsModal: React.FC<TermsModalProps> = ({ isOpen, onClose }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center p-4">
      {/* Backdrop */}
      <div 
        className="absolute inset-0 bg-black/80 backdrop-blur-sm"
        onClick={onClose}
      />
      
      {/* Modal */}
      <div className="relative bg-black/90 border border-white/10 rounded-xl max-w-2xl w-full max-h-[80vh] overflow-hidden">
        <div className="flex items-center justify-between p-6 border-b border-white/10">
          <h2 className="text-xl font-semibold text-white">Terms and Conditions</h2>
          <button
            onClick={onClose}
            className="text-gray-400 hover:text-white transition-colors"
          >
            <svg className="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>
        
        <div className="p-6 overflow-y-auto max-h-[60vh] space-y-4 text-gray-300">
          <section>
            <h3 className="text-lg font-medium text-white mb-2">1. Acceptance of Terms</h3>
            <p>
              By accessing and using Track Countries, you agree to be bound by these Terms and Conditions.
            </p>
          </section>

          <section>
            <h3 className="text-lg font-medium text-white mb-2">2. User Data</h3>
            <p>
              We collect and store information about the countries you've visited and any associated media you choose to share.
              This information is used to provide you with the service and may be shared with other users through the social features.
            </p>
          </section>

          <section>
            <h3 className="text-lg font-medium text-white mb-2">3. User Conduct</h3>
            <p>
              You agree to use the service responsibly and not to:
            </p>
            <ul className="list-disc list-inside mt-2 space-y-1">
              <li>Share inappropriate or offensive content</li>
              <li>Misrepresent your travel history</li>
              <li>Attempt to abuse or exploit the service</li>
              <li>Violate any applicable laws or regulations</li>
            </ul>
          </section>

          <section>
            <h3 className="text-lg font-medium text-white mb-2">4. Privacy</h3>
            <p>
              We respect your privacy and handle your data in accordance with our privacy policy.
              Your travel data is stored securely and you control what information is shared publicly.
            </p>
          </section>

          <section>
            <h3 className="text-lg font-medium text-white mb-2">5. Modifications</h3>
            <p>
              We reserve the right to modify these terms at any time. Continued use of the service
              after changes constitutes acceptance of the new terms.
            </p>
          </section>
        </div>

        <div className="p-6 border-t border-white/10 flex justify-end">
          <button
            onClick={onClose}
            className="px-4 py-2 bg-gradient-to-r from-blue-400 to-green-600 hover:from-blue-500 hover:to-green-500 text-white rounded-lg transition-all duration-200"
          >
            I Understand
          </button>
        </div>
      </div>
    </div>
  );
};

export default TermsModal; 