import * as THREE from 'three';

export const createStarfield = () => (scene: THREE.Scene) => {
  const starsGeometry = new THREE.BufferGeometry();
  const starsMaterial = new THREE.PointsMaterial({
    color: 0xFFFFFF,
    size: 0.12,
    transparent: true,
    opacity: 0.8,
    sizeAttenuation: true
  });

  const starsCount = 2500;
  const radius = 800;

  // Pre-allocate arrays for better performance
  const positions = new Float32Array(starsCount * 3);
  const colors = new Float32Array(starsCount * 3);

  for (let i = 0; i < starsCount; i++) {
    const theta = 2 * Math.PI * Math.random();
    const phi = Math.acos(2 * Math.random() - 1);
    const x = radius * Math.sin(phi) * Math.cos(theta);
    const y = radius * Math.sin(phi) * Math.sin(theta);
    const z = radius * Math.cos(phi);
    
    positions[i * 3] = x;
    positions[i * 3 + 1] = y;
    positions[i * 3 + 2] = z;

    const t = Math.random();
    colors[i * 3] = 0.5 + t * 0.5;     // R
    colors[i * 3 + 1] = 0.5 + t * 0.5; // G
    colors[i * 3 + 2] = 0.5 + t * 0.5; // B
  }

  starsGeometry.setAttribute('position', new THREE.Float32BufferAttribute(positions, 3));
  starsGeometry.setAttribute('color', new THREE.Float32BufferAttribute(colors, 3));

  const starField = new THREE.Points(starsGeometry, starsMaterial);
  scene.add(starField);

  let rotationSpeed = 0.0001;

  const render = () => {
    starField.rotation.y += rotationSpeed;
  };

  const dispose = () => {
    scene.remove(starField);
    starsGeometry.dispose();
    starsMaterial.dispose();
  };

  return { render, dispose };
}; 