import { useState } from 'react';

interface StatusCardProps {
    visitedCount: number;
    totalCount: number;
    visitedCountries: Set<string>;
    onCountryClick: (country: string) => void;
    isAuthenticated: boolean;
    allCountries: string[];
    setAllCountries: (countries: string[]) => void;
  }
  
  const StatusCard: React.FC<StatusCardProps> = ({ 
    visitedCount, 
    totalCount, 
    visitedCountries,
    onCountryClick,
    isAuthenticated,
    allCountries,
    setAllCountries
  }) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const [activeFilter, setActiveFilter] = useState<'all' | 'visited' | 'unvisited'>('all');
  
    const filteredCountries = allCountries.filter(country => {
      const matchesSearch = country.toLowerCase().includes(searchQuery.toLowerCase());
      if (!matchesSearch) return false;
  
      switch (activeFilter) {
        case 'visited':
          return visitedCountries.has(country);
        case 'unvisited':
          return !visitedCountries.has(country);
        default:
          return true;
      }
    });
  
    return (
      <div className="w-full">
        <div className="bg-black/30 backdrop-blur-sm border border-white/10 rounded-lg overflow-hidden max-h-[50vh] w-[320px]">
          {isExpanded && (
            <div className="border-b border-white/10">
              <div className="p-4">
                <div className="relative">
                  <input
                    type="text"
                    placeholder="Search countries..."
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    className="w-full px-3 py-2 bg-black/20 border border-white/10 rounded-lg text-white/70 placeholder-white/30 focus:outline-none focus:border-blue-400 pr-10"
                  />
                  {searchQuery && (
                    <button
                      onClick={() => setSearchQuery('')}
                      className="absolute right-3 top-1/2 -translate-y-1/2 text-white/50 hover:text-white/70 transition-colors"
                    >
                      <svg className="w-4 h-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                      </svg>
                    </button>
                  )}
                </div>
                
                {/* Filter Buttons */}
                <div className="flex space-x-2 mt-3">
                  <button
                    onClick={() => setActiveFilter('all')}
                    className={`flex-1 px-3 py-1.5 rounded-lg text-sm transition-colors ${
                      activeFilter === 'all'
                        ? 'bg-blue-500 text-white'
                        : 'bg-white/5 text-white/70 hover:bg-white/10'
                    }`}
                  >
                    All
                  </button>
                  <button
                    onClick={() => setActiveFilter('visited')}
                    className={`flex-1 px-3 py-1.5 rounded-lg text-sm transition-colors ${
                      activeFilter === 'visited'
                        ? 'bg-green-500 text-white'
                        : 'bg-white/5 text-white/70 hover:bg-white/10'
                    }`}
                  >
                    Visited
                  </button>
                  <button
                    onClick={() => setActiveFilter('unvisited')}
                    className={`flex-1 px-3 py-1.5 rounded-lg text-sm transition-colors ${
                      activeFilter === 'unvisited'
                        ? 'bg-gray-500 text-white'
                        : 'bg-white/5 text-white/70 hover:bg-white/10'
                    }`}
                  >
                    Unvisited
                  </button>
                </div>
              </div>
              <div className="max-h-[30vh] overflow-y-auto scrollbar-thin scrollbar-thumb-white/10 scrollbar-track-transparent bg-black/30 backdrop-blur-sm">
                <div className="p-4 space-y-2">
                  {filteredCountries.map(country => (
                    <button
                      key={country}
                      onClick={() => onCountryClick(country)}
                      className="w-full flex items-center justify-between text-sm hover:bg-white/5 px-2 py-1 rounded transition-colors"
                    >
                      <span className="text-white/70 truncate">{country}</span>
                      {visitedCountries.has(country) ? (
                        <svg className="w-4 h-4 text-green-500 flex-shrink-0" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                        </svg>
                      ) : (
                        <svg className="w-4 h-4 text-white/30 flex-shrink-0" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                        </svg>
                      )}
                    </button>
                  ))}
                </div>
              </div>
            </div>
          )}
          
          {!isAuthenticated && (
            <div className="px-4 py-2 bg-yellow-500/10 border border-yellow-500/20 rounded-t-lg">
              <p className="text-yellow-400 text-sm">
                Log in to save your visited countries
              </p>
            </div>
          )}
          
          <div className="px-4 py-3 flex items-center justify-between">
            <div className="flex items-center space-x-3 min-w-[200px]">
                <div className="relative w-6 h-6">
                  <svg className="w-6 h-6 transform -rotate-90">
                    <circle
                      className="text-white/10"
                      strokeWidth="2"
                      stroke="currentColor"
                      fill="transparent"
                      r="8"
                      cx="12"
                      cy="12"
                    />
                    <circle
                      className="text-green-500"
                      strokeWidth="2"
                      strokeDasharray={`${(visitedCount / totalCount) * 50} 50`}
                      strokeLinecap="round"
                      stroke="currentColor"
                      fill="transparent"
                      r="8"
                      cx="12"
                      cy="12"
                    />
                  </svg>
                </div>
              <span className="text-white/70">
                {`Visited ${visitedCount} of ${totalCount} countries`}
              </span>
            </div>
            <button 
              onClick={() => setIsExpanded(!isExpanded)}
              className="text-white/50 hover:text-white/70 transition-colors ml-4"
            >
              <svg 
                className={`w-5 h-5 transform transition-transform ${isExpanded ? 'rotate-180' : ''}`}
                fill="none" 
                viewBox="0 0 24 24" 
                stroke="currentColor"
              >
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
              </svg>
            </button>
          </div>
        </div>
      </div>
    );
  };

  export default StatusCard;