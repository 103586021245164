import React, { createContext, useContext, useEffect, useState } from 'react';
import { 
  User,
  AuthError,
  AuthResponse,
  SignInWithPasswordCredentials,
  SignUpWithPasswordCredentials,
  Session
} from '@supabase/supabase-js';
import { supabase } from '../services/supabase';

interface AuthContextType {
  user: User | null;
  loading: boolean;
  signIn: (email: string, password: string) => Promise<void>;
  signUp: (email: string, password: string, fullName: string) => Promise<{ user: User | null; session: Session | null }>;
  logout: () => Promise<void>;
  updateUserProfile: (data: { full_name?: string }) => Promise<void>;
  forgotPassword: (email: string) => Promise<void>;
  resetPassword: (newPassword: string) => Promise<void>;
  signInWithGoogle: () => Promise<void>;
  handleOAuthCallback: () => Promise<void>;
}

const AuthContext = createContext<AuthContextType>({
  user: null,
  loading: true,
  signIn: async () => {},
  signUp: async () => { throw new Error('Not implemented') },
  logout: async () => {},
  updateUserProfile: async () => {},
  forgotPassword: async () => {},
  resetPassword: async () => {},
  signInWithGoogle: async () => {},
  handleOAuthCallback: async () => {}
});

export const useAuth = () => useContext(AuthContext);

interface UserData {
  full_name: string;
  email: string;
  created_at: string;
  last_login_at: string;
}

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Get initial session
    supabase.auth.getSession().then(({ data: { session } }) => {
      setUser(session?.user ?? null);
      setLoading(false);
    });

    // Listen for auth changes
    const { data: { subscription } } = supabase.auth.onAuthStateChange((_event, session) => {
      setUser(session?.user ?? null);
      setLoading(false);
    });

    return () => subscription.unsubscribe();
  }, []);

  const saveUserToDatabase = async (user: User, fullName: string) => {
    const { data: existingUser } = await supabase
      .from('users')
      .select()
      .eq('id', user.id)
      .single();

    if (!existingUser) {
      // Create new user
      await supabase.from('users').insert({
        id: user.id,
        full_name: fullName,
        email: user.email,
        created_at: new Date().toISOString(),
        last_login_at: new Date().toISOString()
      });
    } else {
      // Update existing user
      await supabase
        .from('users')
        .update({ last_login_at: new Date().toISOString() })
        .eq('id', user.id);
    }
  };

  const signUp = async (email: string, password: string, fullName: string) => {
    const { data, error } = await supabase.auth.signUp({
      email,
      password,
      options: {
        data: {
          full_name: fullName
        }
      }
    });

    if (error) throw error;
    return data;
  };

  const signIn = async (email: string, password: string) => {
    const { error } = await supabase.auth.signInWithPassword({
      email,
      password
    });

    if (error) throw error;
  };

  const logout = async () => {
    const { error } = await supabase.auth.signOut();
    if (error) throw error;
  };

  const updateUserProfile = async (data: { full_name?: string }) => {
    const { error } = await supabase.auth.updateUser({
      data: { full_name: data.full_name }
    });

    if (error) throw error;
  };

  const forgotPassword = async (email: string) => {
    const { error } = await supabase.auth.resetPasswordForEmail(email, {
      redirectTo: `${window.location.origin}/reset-password`
    });

    if (error) throw error;
  };

  const resetPassword = async (newPassword: string) => {
    const { error } = await supabase.auth.updateUser({
      password: newPassword
    });

    if (error) throw error;
  };

  const signInWithGoogle = async () => {
    const { error } = await supabase.auth.signInWithOAuth({
      provider: 'google',
      options: {
        redirectTo: `${window.location.origin}/auth/callback`
      }
    });

    if (error) throw error;
  };

  // Add a new function to handle the OAuth callback
  const handleOAuthCallback = async () => {
    const { data: { session }, error } = await supabase.auth.getSession();
    
    if (error) throw error;
    
    if (session?.user) {
      const { data: existingUser } = await supabase
        .from('users')
        .select()
        .eq('id', session.user.id)
        .maybeSingle();

      if (!existingUser) {
        // Create new user with Google profile data
        await supabase.from('users').insert({
          id: session.user.id,
          full_name: session.user.user_metadata.full_name || '',
          email: session.user.email,
          created_at: new Date().toISOString(),
          last_login_at: new Date().toISOString()
        });
      } else {
        // Update last login for existing user
        await supabase
          .from('users')
          .update({ last_login_at: new Date().toISOString() })
          .eq('id', session.user.id);
      }
    }
  };

  const value = {
    user,
    loading,
    signIn,
    signUp,
    logout,
    updateUserProfile,
    forgotPassword,
    resetPassword,
    signInWithGoogle,
    handleOAuthCallback
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
}; 