import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import UserSettings from './UserSettings';

interface AuthNavProps {
  onAction: () => void;
}

const AuthNav: React.FC<AuthNavProps> = ({ onAction }) => {
  const { user, logout } = useAuth();
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);

  const handleLogout = async () => {
    await logout();
    onAction();
  };

  if (!user) {
    return (
      <div className="flex items-center space-x-2">
        <Link
          to="/login"
          onClick={onAction}
          className="px-4 py-2 text-sm text-white hover:bg-white/5 rounded-lg transition-colors"
        >
          Log In
        </Link>
        <Link
          to="/signup"
          onClick={onAction}
          className="px-4 py-2 text-sm bg-gradient-to-r from-blue-400 to-green-600 hover:from-blue-500 hover:to-green-700 rounded-lg transition-all duration-200 text-white font-medium"
        >
          Sign Up
        </Link>
      </div>
    );
  }

  return (
    <>
      <button
        onClick={() => setIsSettingsOpen(true)}
        className="flex items-center space-x-2 hover:bg-white/5 px-3 py-2 rounded-lg transition-colors"
      >
        <div className="w-8 h-8 rounded-full bg-gradient-to-r from-blue-400 to-green-600 flex items-center justify-center text-white font-medium">
          {user.user_metadata?.full_name?.[0] || user.email?.[0] || '?'}
        </div>
      </button>
      <UserSettings isOpen={isSettingsOpen} onClose={() => setIsSettingsOpen(false)} />
    </>
  );
};

export default AuthNav; 