import React, { useEffect, useState, useRef } from 'react';
import { useAuth } from '../contexts/AuthContext';
import GlobeVisualization from '../components/GlobeVisualization';
import { supabase } from '../services/supabase';
import { usePageTitle } from '../hooks/usePageTitle';
import { GeoJSONData } from '../types/geojson';

const Globe: React.FC = () => {
  usePageTitle('Globe');
  const { user } = useAuth();
  const [allCountries, setAllCountries] = useState<string[]>([]);
  const [visitedCountries, setVisitedCountries] = useState<Set<string>>(new Set());
  const [pendingChanges, setPendingChanges] = useState<Set<string>>(new Set());
  const [isLoading, setIsLoading] = useState(true);
  const [geoJsonData, setGeoJsonData] = useState<GeoJSONData | null>(null);
  const hasInitialized = useRef(false);
  const originalCountries = useRef<Set<string>>(new Set());
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    console.log('Globe useEffect triggered, user:', user?.id, 'hasInitialized:', hasInitialized.current);
    
    // Skip if we've already initialized
    if (hasInitialized.current) return;

    const fetchData = async () => {
      try {
        console.log('Fetching GeoJSON data...');
        // Fetch GeoJSON data
        const response = await fetch('/assets/geojson/ne_110m_admin_0_countries.geojson');
        const data: GeoJSONData = await response.json();
        console.log('GeoJSON data fetched successfully');
        setGeoJsonData(data);
        
        const countries = data.features
          .filter(d => d.properties.ISO_A2 !== 'AQ')
          .map(d => d.properties.ADMIN)
          .sort();
        setAllCountries(countries);

        // Fetch user's visited countries if authenticated
        if (user) {
          console.log('Fetching user countries for user:', user.id);
          const { data: visitedData, error: visitedError } = await supabase
            .from('user_countries')
            .select('country')
            .eq('user_id', user.id);

          if (visitedError) throw visitedError;

          const validCountries = visitedData
            ?.map(row => row.country)
            .filter(country => countries.includes(country)) || [];
          
          console.log('User countries fetched successfully:', validCountries.length);
          const newSet = new Set(validCountries);
          setVisitedCountries(newSet);
          setPendingChanges(newSet);
          originalCountries.current = new Set(newSet);
        }

        hasInitialized.current = true;
      } catch (error) {
        console.error('Error loading data:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [user?.id]); // Only depend on the user ID instead of the whole user object

  const handleCountryToggle = (country: string) => {
    setPendingChanges(prev => {
      const newChanges = new Set(prev);
      if (newChanges.has(country)) {
        newChanges.delete(country);
      } else {
        newChanges.add(country);
      }
      return newChanges;
    });
  };

  const handleSave = async () => {
    if (!user) return;

    try {
      // First, delete all existing countries for this user
      const { error: deleteError } = await supabase
        .from('user_countries')
        .delete()
        .eq('user_id', user.id);

      if (deleteError) throw deleteError;

      // Then insert all current countries
      if (pendingChanges.size > 0) {
        const { error: insertError } = await supabase
          .from('user_countries')
          .insert(
            Array.from(pendingChanges).map(country => ({
              user_id: user.id,
              country: country
            }))
          );

        if (insertError) throw insertError;
      }

      // Update visited countries with the current pending changes
      setVisitedCountries(new Set(pendingChanges));
      // Update pending changes to match visited countries
      setPendingChanges(new Set(pendingChanges));
      // Update original countries to match current state
      originalCountries.current = new Set(pendingChanges);
      
      setSuccessMessage('Changes saved successfully');
      setTimeout(() => setSuccessMessage(null), 3000);
    } catch (error) {
      console.error('Error updating countries:', error);
      setError('Failed to update countries');
    }
  };

  const handleReset = () => {
    setPendingChanges(new Set(visitedCountries));
  };

  const handleClear = () => {
    // Clear both pending changes and visited countries
    setPendingChanges(new Set());
    setVisitedCountries(new Set());
  };

  if (isLoading || !geoJsonData) {
    return (
      <div className="min-h-screen bg-[#0A0A0A] flex items-center justify-center">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  return (
    <div className="relative w-full h-screen bg-[#0A0A0A] overflow-hidden">
      <GlobeVisualization 
        allCountries={allCountries}
        visitedCountries={pendingChanges}
        onCountryToggle={handleCountryToggle}
        geoJsonData={geoJsonData}
        onSave={handleSave}
        onReset={handleReset}
        onClear={handleClear}
        hasPendingChanges={!Array.from(pendingChanges).every(country => originalCountries.current.has(country)) || 
                          !Array.from(originalCountries.current).every(country => pendingChanges.has(country))}
      />
    </div>
  );
};

export default Globe; 