import React, { useState, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { usePageTitle } from '../hooks/usePageTitle';
import { supabase } from '../services/supabase';
import { validatePassword } from '../utils/validation';

const ResetPassword: React.FC = () => {
  usePageTitle('Reset Password');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isValidCode, setIsValidCode] = useState(false);
  const [validationErrors, setValidationErrors] = useState<Record<string, string>>({});
  const { resetPassword } = useAuth();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // Check if we have a valid reset token
    const checkResetToken = async () => {
      try {
        const { data, error } = await supabase.auth.verifyOtp({
          token_hash: searchParams.get('token') || '',
          type: 'recovery'
        });

        if (error) throw error;
        setIsValidCode(!!data);
      } catch (error) {
        console.error('Error checking reset token:', error);
        setIsValidCode(false);
      }
    };

    checkResetToken();
  }, [searchParams]);

  const validateForm = () => {
    const errors: Record<string, string> = {};
    
    if (!password) {
      errors.password = 'Password is required';
    } else {
      const passwordValidation = validatePassword(password);
      if (!passwordValidation.isValid) {
        errors.password = passwordValidation.errors[0];
      }
    }
    
    if (!confirmPassword) {
      errors.confirmPassword = 'Please confirm your password';
    } else if (password !== confirmPassword) {
      errors.confirmPassword = 'Passwords do not match';
    }
    
    setValidationErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!validateForm()) return;

    try {
      setLoading(true);
      const { error } = await supabase.auth.updateUser({
        password: password
      });

      if (error) throw error;

      setSuccess(true);
      setTimeout(() => navigate('/login'), 2000);
    } catch (err: any) {
      setValidationErrors({ submit: err.message || 'Failed to reset password' });
    } finally {
      setLoading(false);
    }
  };

  if (!isValidCode) {
    return (
      <div className="min-h-screen bg-[#0A0A0A] flex items-center justify-center px-4 pt-16">
        <div className="max-w-md w-full">
          <div className="text-center mb-10">
            <h2 className="text-3xl font-bold bg-gradient-to-r from-blue-400 to-green-600 bg-clip-text text-transparent mb-2">
              Invalid Reset Link
            </h2>
            <p className="text-gray-400">This password reset link is invalid or has expired.</p>
          </div>

          <div className="bg-black/30 backdrop-blur-xl border border-white/10 rounded-xl p-8">
            <div className="text-center space-y-4">
              <p className="text-gray-400">
                Please request a new password reset link.
              </p>
              <button
                onClick={() => navigate('/forgot-password')}
                className="w-full py-3 px-4 rounded-lg text-white font-medium bg-gradient-to-r from-blue-400 to-green-600 hover:from-blue-500 hover:to-green-500 transition-all duration-200 transform hover:scale-[1.02]"
              >
                Request New Reset Link
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-[#0A0A0A] flex items-center justify-center px-4 pt-16">
      <div className="max-w-md w-full">
        <div className="text-center mb-10">
          <h2 className="text-3xl font-bold bg-gradient-to-r from-blue-400 to-green-600 bg-clip-text text-transparent mb-2">
            Reset Your Password
          </h2>
          <p className="text-gray-400">Create a new password for your account</p>
        </div>

        <div className="bg-black/30 backdrop-blur-xl border border-white/10 rounded-xl p-8">
          {success ? (
            <div className="text-center space-y-4">
              <div className="text-green-400">
                <svg className="mx-auto h-12 w-12" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                </svg>
              </div>
              <p className="text-white/70">
                Password reset successful! Redirecting to login...
              </p>
            </div>
          ) : (
            <form onSubmit={handleSubmit} className="space-y-6">
              {validationErrors.submit && (
                <div className="bg-red-500/10 border border-red-500/20 text-red-400 px-4 py-3 rounded-lg">
                  {validationErrors.submit}
                </div>
              )}

              <div>
                <label htmlFor="password" className="block text-sm font-medium text-gray-300 mb-2">
                  New Password
                </label>
                <input
                  type="password"
                  id="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className={`w-full px-4 py-3 bg-black/50 border ${
                    validationErrors.password ? 'border-red-500' : 'border-white/10'
                  } rounded-lg text-white placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-all duration-200`}
                  placeholder="Enter your new password"
                  required
                />
                {validationErrors.password && (
                  <p className="mt-1 text-sm text-red-500">{validationErrors.password}</p>
                )}
                <div className="mt-2 text-sm text-gray-400">
                  Password must contain:
                  <ul className="list-disc list-inside mt-1">
                    <li>At least 8 characters</li>
                    <li>At least one uppercase letter</li>
                    <li>At least one lowercase letter</li>
                    <li>At least one number</li>
                    <li>At least one special symbol (!@#$%^&amp;*(),.?&quot;:&#123;&#125;|&lt;&gt;)</li>
                  </ul>
                </div>
              </div>

              <div>
                <label htmlFor="confirmPassword" className="block text-sm font-medium text-gray-300 mb-2">
                  Confirm Password
                </label>
                <input
                  type="password"
                  id="confirmPassword"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  className={`w-full px-4 py-3 bg-black/50 border ${
                    validationErrors.confirmPassword ? 'border-red-500' : 'border-white/10'
                  } rounded-lg text-white placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-all duration-200`}
                  placeholder="Confirm your new password"
                  required
                />
                {validationErrors.confirmPassword && (
                  <p className="mt-1 text-sm text-red-500">{validationErrors.confirmPassword}</p>
                )}
              </div>

              <button
                type="submit"
                disabled={loading}
                className={`w-full py-3 px-4 rounded-lg text-white font-medium 
                  ${loading 
                    ? 'bg-gray-600 cursor-not-allowed' 
                    : 'bg-gradient-to-r from-blue-400 to-green-600 hover:from-blue-500 hover:to-green-500'} 
                  transition-all duration-200 transform hover:scale-[1.02]`}
              >
                {loading ? 'Resetting password...' : 'Reset Password'}
              </button>

              <div className="text-center text-sm text-gray-400">
                Remember your password?{' '}
                <button
                  onClick={() => navigate('/login')}
                  className="text-blue-400 hover:text-blue-300"
                >
                  Sign in
                </button>
              </div>
            </form>
          )}
        </div>
      </div>
    </div>
  );
};

export default ResetPassword; 