import React, { useState, useRef, useEffect } from 'react';
import { GlobeInstance } from 'globe.gl';
import GIF from 'gif.js';
import * as THREE from 'three';

interface GeoJSONFeature {
  type: string;
  properties: {
    ADMIN: string;
    ISO_A2: string;
    [key: string]: any;
  };
  geometry: {
    type: string;
    coordinates: number[][][];
  };
}

interface GeoJSONData {
  type: string;
  features: GeoJSONFeature[];
}

interface ShareModalProps {
  isOpen: boolean;
  onClose: () => void;
  visitedCountries: Set<string>;
  totalCountries: number;
  globeRef: React.RefObject<GlobeInstance>;
}

type ImageQuality = 'low' | 'medium' | 'high';
type ShareOption = 'globe' | 'map' | null;

const ShareModal: React.FC<ShareModalProps> = ({
  isOpen,
  onClose,
  visitedCountries,
  totalCountries,
  globeRef
}) => {
  const [selectedOption, setSelectedOption] = useState<ShareOption>(null);
  const [selectedQuality, setSelectedQuality] = useState<ImageQuality>('medium');
  const [isGenerating, setIsGenerating] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [progress, setProgress] = useState(0);
  const animationFrameRef = useRef<number>();
  const lastUpdateTimeRef = useRef<number>(0);
  const gifWorkerRef = useRef<GIF | null>(null);

  // Cleanup on unmount or when modal is closed
  useEffect(() => {
    return () => {
      if (animationFrameRef.current) {
        cancelAnimationFrame(animationFrameRef.current);
      }
      if (gifWorkerRef.current) {
        gifWorkerRef.current.abort();
      }
      if (globeRef.current) {
        const globe = globeRef.current;
        const camera = globe.camera() as THREE.PerspectiveCamera;
        camera.fov = 45;
        camera.updateProjectionMatrix();
      }
    };
  }, [globeRef]);

  // Reset globe when modal is closed
  useEffect(() => {
    if (!isOpen && globeRef.current) {
      const globe = globeRef.current;
      const camera = globe.camera() as THREE.PerspectiveCamera;
      camera.fov = 45;
      camera.updateProjectionMatrix();
    }
  }, [isOpen, globeRef]);

  // Smooth progress updates using requestAnimationFrame
  const updateProgress = (currentTime: number) => {
    if (!lastUpdateTimeRef.current) {
      lastUpdateTimeRef.current = currentTime;
    }
    
    const deltaTime = currentTime - lastUpdateTimeRef.current;
    if (deltaTime >= 16) { // Update at ~60fps
      setProgress(prev => Math.min(prev + 1, 100));
      lastUpdateTimeRef.current = currentTime;
    }
    
    if (isGenerating) {
      animationFrameRef.current = requestAnimationFrame(updateProgress);
    }
  };

  useEffect(() => {
    if (isGenerating) {
      animationFrameRef.current = requestAnimationFrame(updateProgress);
    }
    return () => {
      if (animationFrameRef.current) {
        cancelAnimationFrame(animationFrameRef.current);
      }
    };
  }, [isGenerating]);

  // Add black background during generation
  useEffect(() => {
    if (isGenerating && globeRef.current) {
      const globe = globeRef.current;
      const originalBackground = globe.renderer().getClearColor(new THREE.Color());
      globe.renderer().setClearColor(new THREE.Color(0x000000));
      return () => {
        globe.renderer().setClearColor(originalBackground);
      };
    }
  }, [isGenerating, globeRef]);

  // Add escape key handler
  useEffect(() => {
    const handleEscape = (e: KeyboardEvent) => {
      if (e.key === 'Escape' && !isGenerating) {
        handleClose();
      }
    };

    window.addEventListener('keydown', handleEscape);
    return () => window.removeEventListener('keydown', handleEscape);
  }, [isGenerating]);

  const handleClose = () => {
    if (gifWorkerRef.current) {
      gifWorkerRef.current.abort();
    }
    if (globeRef.current) {
      const globe = globeRef.current;
      const camera = globe.camera() as THREE.PerspectiveCamera;
      camera.fov = 45;
      camera.updateProjectionMatrix();
    }
    onClose();
  };

  const generateGlobeGIF = async () => {
    if (!globeRef.current) {
      throw new Error('Globe not initialized');
    }

    const globe = globeRef.current;
    const canvas = globe.renderer().domElement;
    // Adjust dimensions based on quality
    const width = selectedQuality === 'low' ? 300 : selectedQuality === 'medium' ? 400 : 600;
    const height = width;
    const fps = selectedQuality === 'low' ? 2 : selectedQuality === 'medium' ? 3 : 4;
    const duration = 16000;
    const totalFrames = Math.floor((duration / 1000) * fps);
    const frames: ImageData[] = [];

    // Store original globe settings
    const originalAtmosphereColor = globe.atmosphereColor();
    const originalAtmosphereAltitude = globe.atmosphereAltitude();

    // Remove atmosphere for GIF
    globe.atmosphereColor('transparent');
    globe.atmosphereAltitude(0);

    // Create a temporary canvas for resizing and watermark
    const tempCanvas = document.createElement('canvas');
    const tempCtx = tempCanvas.getContext('2d');
    if (!tempCtx) throw new Error('Could not get canvas context');
    tempCanvas.width = width;
    tempCanvas.height = height;

    // Create watermark canvas
    const watermarkCanvas = document.createElement('canvas');
    const watermarkCtx = watermarkCanvas.getContext('2d');
    if (!watermarkCtx) throw new Error('Could not get watermark canvas context');
    watermarkCanvas.width = width;
    watermarkCanvas.height = height;

    // Draw watermark
    watermarkCtx.fillStyle = 'rgba(0, 0, 0, 0)';
    watermarkCtx.fillRect(0, 0, width, height);
    
    // Add visited.ai text with scaled size
    const watermarkSize = selectedQuality === 'low' ? 16 : selectedQuality === 'medium' ? 24 : 36;
    const watermarkPadding = selectedQuality === 'low' ? 16 : selectedQuality === 'medium' ? 24 : 36;
    
    watermarkCtx.font = `bold ${watermarkSize}px Inter, system-ui, -apple-system, sans-serif`;
    watermarkCtx.fillStyle = 'rgba(255, 255, 255, 0.8)';
    watermarkCtx.textAlign = 'right';
    watermarkCtx.textBaseline = 'bottom';
    watermarkCtx.fillText('visited.ai', width - watermarkPadding, height - watermarkPadding);

    // Store original camera settings
    const camera = globe.camera() as THREE.PerspectiveCamera;
    const originalCamera = camera.position.clone();
    const originalFOV = camera.fov;
    const originalUp = camera.up.clone();
    const originalAspect = camera.aspect;

    // Adjust camera for full globe view
    camera.fov = 45;
    camera.aspect = 1;
    camera.up.set(0, 1, 0);
    camera.updateProjectionMatrix();
    const radius = 270;

    // Create GIF encoder with quality-based settings
    const gif = new GIF({
      workers: 4,
      quality: selectedQuality === 'low' ? 5 : selectedQuality === 'medium' ? 1 : 1,
      width,
      height,
      workerScript: '/gif.worker.js',
      dither: selectedQuality !== 'low', // Disable dithering for low quality
      repeat: 0,
      background: '#000000'
    });
    gifWorkerRef.current = gif;

    // Generate frames
    for (let i = 0; i < totalFrames; i++) {
      const progress = i / totalFrames;
      const angle = progress * Math.PI * 2;
      
      camera.position.x = Math.cos(angle) * radius;
      camera.position.z = Math.sin(angle) * radius;
      camera.position.y = 0;
      camera.lookAt(new THREE.Vector3(0, 0, 0));

      globe.renderer().render(globe.scene(), camera);

      const frameData = globe.renderer().domElement.toDataURL('image/png');
      const img = new Image();
      await new Promise((resolve) => {
        img.onload = resolve;
        img.src = frameData;
      });
      
      tempCtx.fillStyle = '#000000';
      tempCtx.fillRect(0, 0, width, height);
      tempCtx.drawImage(img, 0, 0, width, height);
      tempCtx.drawImage(watermarkCanvas, 0, 0);
      
      frames.push(tempCtx.getImageData(0, 0, width, height));
      
      // Update progress to 75% during frame generation
      setProgress(Math.round((i / totalFrames) * 75));
    }

    // Restore original settings
    camera.position.copy(originalCamera);
    camera.fov = originalFOV;
    camera.up.copy(originalUp);
    camera.aspect = originalAspect;
    camera.updateProjectionMatrix();
    globe.atmosphereColor(originalAtmosphereColor);
    globe.atmosphereAltitude(originalAtmosphereAltitude);

    // Add frames to GIF and track encoding progress
    frames.forEach((frame, index) => {
      gif.addFrame(frame, { delay: 333 });
      // Update progress from 75% to 90% during frame addition
      setProgress(75 + Math.round((index / frames.length) * 15));
    });

    // Generate GIF
    return new Promise<Blob>((resolve, reject) => {
      gif.on('progress', (p: number) => {
        // Update progress from 90% to 100% during final encoding
        setProgress(90 + Math.round(p * 10));
      });
      gif.on('finished', (blob: Blob) => {
        gifWorkerRef.current = null;
        setProgress(100);
        resolve(blob);
      });
      gif.on('abort', () => {
        gifWorkerRef.current = null;
        reject(new Error('GIF generation aborted'));
      });
      gif.render();
    });
  };

  const generate2DMap = async () => {
    if (!globeRef.current) {
      throw new Error('Globe not initialized');
    }

    const globe = globeRef.current;
    // Adjust width based on quality
    const baseWidth = selectedQuality === 'low' ? 800 : selectedQuality === 'medium' ? 1600 : 3200;
    const width = baseWidth;
    const height = width / 2; // Half of width for equirectangular projection
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    if (!ctx) throw new Error('Could not get canvas context');
    canvas.width = width;
    canvas.height = height;

    // Load earth texture
    const earthTexture = new Image();
    earthTexture.crossOrigin = 'anonymous';
    await new Promise((resolve, reject) => {
      earthTexture.onload = resolve;
      earthTexture.onerror = reject;
      earthTexture.src = 'https://unpkg.com/three-globe/example/img/earth-dark.jpg';
    });

    // Draw earth texture
    ctx.drawImage(earthTexture, 0, 0, width, height);

    // Get GeoJSON data
    const features = globe.polygonsData() as unknown as GeoJSONFeature[];
    if (!Array.isArray(features)) {
      console.error('Invalid GeoJSON data:', features);
      throw new Error('Invalid GeoJSON data structure');
    }

    // Draw country borders and fill visited countries
    features.forEach((feature: GeoJSONFeature) => {
      if (!feature || !feature.geometry || !Array.isArray(feature.geometry.coordinates)) {
        console.warn('Invalid feature data:', feature);
        return;
      }

      const countryName = feature.properties?.ADMIN;
      if (!countryName) {
        console.warn('Missing country code for feature:', feature);
        return;
      }

      const isVisited = visitedCountries.has(countryName);
      
      // Draw country borders
      ctx.beginPath();
      
      // Handle both single polygon and multi-polygon geometries
      const coordinates = feature.geometry.coordinates;
      if (feature.geometry.type === 'Polygon') {
        // Single polygon - coordinates is number[][][]
        coordinates.forEach((ring: number[][]) => {
          ring.forEach((coord: number[], i: number) => {
            if (!Array.isArray(coord) || coord.length < 2) {
              console.warn('Invalid coordinate data:', coord);
              return;
            }
            const x = (coord[0] + 180) * (width / 360);
            const y = (90 - coord[1]) * (height / 180);
            
            if (i === 0) {
              ctx.moveTo(x, y);
            } else {
              ctx.lineTo(x, y);
            }
          });
        });
      } else if (feature.geometry.type === 'MultiPolygon') {
        // Multi polygon - coordinates is number[][][][]
        (coordinates as unknown as number[][][][]).forEach((polygon: number[][][]) => {
          polygon.forEach((ring: number[][]) => {
            ring.forEach((coord: number[], i: number) => {
              if (!Array.isArray(coord) || coord.length < 2) {
                console.warn('Invalid coordinate data:', coord);
                return;
              }
              const x = (coord[0] + 180) * (width / 360);
              const y = (90 - coord[1]) * (height / 180);
              
              if (i === 0) {
                ctx.moveTo(x, y);
              } else {
                ctx.lineTo(x, y);
              }
            });
          });
        });
      }
      ctx.closePath();

      // Fill visited countries first
      if (isVisited) {
        ctx.fillStyle = 'rgba(34, 197, 94, 0.5)'; // Green with 50% opacity
        ctx.fill();
      }

      // Then draw borders
      ctx.strokeStyle = 'rgba(59, 130, 246, 0.8)';
      ctx.lineWidth = 1;
      ctx.stroke();
    });

    // Add watermark
    const watermarkSize = selectedQuality === 'low' ? 24 : selectedQuality === 'medium' ? 48 : 96;
    const watermarkPadding = selectedQuality === 'low' ? 24 : selectedQuality === 'medium' ? 48 : 96;
    
    ctx.font = `bold ${watermarkSize}px Inter, system-ui, -apple-system, sans-serif`;
    ctx.fillStyle = 'rgba(255, 255, 255, 0.8)';
    ctx.textAlign = 'right';
    ctx.textBaseline = 'bottom';
    ctx.fillText('visited.ai', width - watermarkPadding, height - watermarkPadding);

    // Adjust JPEG quality based on selection
    const jpegQuality = selectedQuality === 'low' ? 0.7 : selectedQuality === 'medium' ? 0.85 : 0.95;

    // Convert to blob with selected quality
    return new Promise<Blob>((resolve) => {
      canvas.toBlob((blob) => {
        if (!blob) throw new Error('Failed to create blob');
        resolve(blob);
      }, 'image/jpeg', jpegQuality);
    });
  };

  const handleShare = async () => {
    if (!selectedOption) return;

    setIsGenerating(true);
    setError(null);
    setProgress(0);

    try {
      if (selectedOption === 'globe') {
        const gifBlob = await generateGlobeGIF();
        
        // Create a download link
        const url = URL.createObjectURL(gifBlob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'travel-globe.gif';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(url);
      } else {
        const jpgBlob = await generate2DMap();
        
        // Create a download link
        const url = URL.createObjectURL(jpgBlob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'travel-map.jpg';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(url);
      }
    } catch (err) {
      setError('Failed to generate shareable image. Please try again.');
      console.error('Share error:', err);
    } finally {
      setIsGenerating(false);
      setProgress(0);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center p-4">
      {/* Backdrop */}
      <div 
        className={`absolute inset-0 bg-black/80 backdrop-blur-sm ${
          isGenerating ? 'cursor-not-allowed' : 'cursor-pointer'
        }`}
        onClick={isGenerating ? undefined : handleClose}
      />
      
      {/* Modal */}
      <div className="relative bg-black/90 border border-white/10 rounded-xl max-w-2xl w-full max-h-[80vh] overflow-hidden">
        <div className="flex items-center justify-between p-6 border-b border-white/10">
          <h2 className="text-xl font-semibold text-white">Share Your Visited Countries</h2>
          {!isGenerating && (
            <button
              onClick={handleClose}
              className="text-gray-400 hover:text-white transition-colors"
            >
              <svg className="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
          )}
        </div>
        
        <div className="p-6 space-y-6">
          <div className="grid grid-cols-2 gap-4">
            <button
              onClick={() => {
                setSelectedOption('globe');
                setSelectedQuality('medium');
              }}
              disabled={isGenerating}
              className={`p-4 rounded-xl border transition-all duration-200 ${
                selectedOption === 'globe'
                  ? 'border-blue-500 bg-blue-500/10'
                  : 'border-white/10 hover:border-white/20'
              } ${isGenerating ? 'opacity-50 cursor-not-allowed' : ''}`}
            >
              <div className="flex flex-col items-center space-y-3">
                <div className="w-16 h-16 rounded-full bg-gradient-to-br from-blue-500/20 to-green-500/20 flex items-center justify-center">
                  <svg className="w-8 h-8 text-blue-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3.055 11H5a2 2 0 012 2v1a2 2 0 002 2 2 2 0 012 2v2.945M8 3.935V5.5A2.5 2.5 0 0010.5 8h.5a2 2 0 012 2 2 2 0 104 0 2 2 0 012-2h1.064M15 20.488V18a2 2 0 012-2h3.064M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                  </svg>
                </div>
                <div className="text-center">
                  <h3 className="text-white font-medium">3D Globe</h3>
                  <p className="text-sm text-gray-400">(GIF)</p>
                </div>
              </div>
            </button>

            <button
              onClick={() => {
                setSelectedOption('map');
                setSelectedQuality('medium');
              }}
              disabled={isGenerating}
              className={`p-4 rounded-xl border transition-all duration-200 ${
                selectedOption === 'map'
                  ? 'border-blue-500 bg-blue-500/10'
                  : 'border-white/10 hover:border-white/20'
              } ${isGenerating ? 'opacity-50 cursor-not-allowed' : ''}`}
            >
              <div className="flex flex-col items-center space-y-3">
                <div className="w-16 h-16 rounded-full bg-gradient-to-br from-blue-500/20 to-green-500/20 flex items-center justify-center">
                  <svg className="w-8 h-8 text-blue-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 20l-5.447-2.724A1 1 0 013 16.382V5.618a1 1 0 011.447-.894L9 7m0 13l6-3m-6 3V7m6 10l4.553 2.276A1 1 0 0021 18.382V7.618a1 1 0 00-.553-.894L15 4m0 13V4m0 0L9 7" />
                  </svg>
                </div>
                <div className="text-center">
                  <h3 className="text-white font-medium">2D Map</h3>
                  <p className="text-sm text-gray-400">(JPG)</p>
                </div>
              </div>
            </button>
          </div>

          {selectedOption && (
            <div className="space-y-2">
              <label className="block text-sm font-medium text-gray-200">Image Quality</label>
              <select
                value={selectedQuality}
                onChange={(e) => setSelectedQuality(e.target.value as ImageQuality)}
                disabled={isGenerating}
                className="w-full px-3 py-2 rounded-lg bg-black/50 border border-white/10 text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent disabled:opacity-50 disabled:cursor-not-allowed"
              >
                <option value="low">Low - Smaller file size, suitable for social media</option>
                <option value="medium">Medium - Balanced quality and file size</option>
                <option value="high">High - Best quality, larger file size</option>
              </select>
              <p className="text-sm text-gray-400">
                {selectedOption === 'map' ? (
                  <>
                    {selectedQuality === 'low' && '(800×400px)'}
                    {selectedQuality === 'medium' && '(1600×800px)'}
                    {selectedQuality === 'high' && '(3200×1600px)'}
                  </>
                ) : (
                  <>
                    {selectedQuality === 'low' && '(300×300px, 2fps)'}
                    {selectedQuality === 'medium' && '(400×400px, 3fps)'}
                    {selectedQuality === 'high' && '(600×600px, 4fps)'}
                  </>
                )}
              </p>
            </div>
          )}

          {error && (
            <div className="p-4 bg-red-500/10 border border-red-500/20 rounded-lg">
              <p className="text-red-400 text-sm">{error}</p>
            </div>
          )}

          {isGenerating && (
            <div className="space-y-2">
              <div className="h-2 bg-white/10 rounded-full overflow-hidden">
                <div 
                  className="h-full bg-gradient-to-r from-blue-500 to-green-500 transition-all duration-300"
                  style={{ width: `${progress}%` }}
                />
              </div>
            </div>
          )}

          <div className="flex justify-end space-x-3">
            <button
              onClick={handleShare}
              disabled={!selectedOption || isGenerating}
              className={`px-6 py-2 rounded-lg text-white font-medium transition-all duration-200 ${
                !selectedOption || isGenerating
                  ? 'opacity-50 cursor-not-allowed'
                  : 'bg-gradient-to-r from-blue-500 to-green-500 hover:from-blue-600 hover:to-green-600'
              }`}
            >
              {isGenerating ? (
                <div className="flex items-center space-x-2">
                  <svg className="animate-spin h-4 w-4" viewBox="0 0 24 24">
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" fill="none" />
                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" />
                  </svg>
                  <span>Generating...</span>
                </div>
              ) : (
                'Share'
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShareModal;