import React, { useState, useEffect, useRef } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { Link } from 'react-router-dom';
import { createPortal } from 'react-dom';

interface UserSettingsProps {
  isOpen: boolean;
  onClose: () => void;
}

interface Position {
  top: number;
  right?: number;
  left?: number;
}

const UserSettings: React.FC<UserSettingsProps> = ({ isOpen, onClose }) => {
  const { user, logout } = useAuth();
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [mounted, setMounted] = useState(false);
  const [position, setPosition] = useState<Position>({ top: 0 });

  useEffect(() => {
    setMounted(true);
    return () => setMounted(false);
  }, []);

  useEffect(() => {
    if (!isOpen || !mounted) return;

    const updatePosition = () => {
      const isMobile = window.innerWidth < 640; // sm breakpoint
      if (isMobile) {
        setPosition({
          top: 200, // Position much lower below the mobile menu
          left: 16 // 1rem from left edge
        });
      } else {
        setPosition({
          top: 104, // 6rem from top
          right: Math.max(32, (window.innerWidth - 1280) / 2 + 32) // Responsive right position
        });
      }
    };

    updatePosition();
    window.addEventListener('resize', updatePosition);

    return () => window.removeEventListener('resize', updatePosition);
  }, [isOpen, mounted]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      // Don't close if clicking on the profile button
      const target = event.target as HTMLElement;
      if (target.closest('.profile-button')) {
        return;
      }
      
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        onClose();
      }
    };

    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen, onClose]);

  if (!isOpen || !mounted) return null;

  const dropdown = (
    <div 
      ref={dropdownRef}
      style={{ 
        isolation: 'isolate',
        position: 'fixed',
        top: `${position.top}px`,
        ...(position.right !== undefined && { right: `${position.right}px` }),
        ...(position.left !== undefined && { left: `${position.left}px` })
      }}
      className="bg-black/30 backdrop-blur-md border border-white/10 rounded-xl shadow-lg w-56 z-[9999999999]"
    >
      <div className="p-4 border-b border-white/10">
        <div>
          <p className="text-sm font-medium text-white">
            Hi, {user?.user_metadata?.full_name || 'User'}
          </p>
          <p className="text-xs text-gray-400">
            {user?.email}
          </p>
        </div>
      </div>

      <div className="p-4 space-y-2">
        <Link
          to="/profile"
          onClick={onClose}
          className="w-full px-4 py-2 text-sm text-white hover:bg-gradient-to-r hover:from-blue-400/10 hover:to-green-600/10 rounded-lg transition-colors flex items-center space-x-2"
        >
          <svg className="w-4 h-4" fill="none" viewBox="0 0 24 24" stroke="url(#profile-gradient)">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z" />
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
            <defs>
              <linearGradient id="profile-gradient" x1="0%" y1="0%" x2="100%" y2="0%">
                <stop offset="0%" stopColor="#60A5FA" />
                <stop offset="100%" stopColor="#22C55E" />
              </linearGradient>
            </defs>
          </svg>
          <span className="bg-gradient-to-r from-blue-400 to-green-600 bg-clip-text text-transparent">Profile</span>
        </Link>

        <button
          onClick={() => {
            logout();
            onClose();
          }}
          className="w-full px-4 py-2 text-sm text-red-400 hover:bg-red-500/10 rounded-lg transition-colors flex items-center space-x-2"
        >
          <svg className="w-4 h-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1" />
          </svg>
          <span>Sign Out</span>
        </button>
      </div>
    </div>
  );

  return createPortal(dropdown, document.body);
};

export default UserSettings; 