import React from 'react';

interface HelpModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const HelpModal: React.FC<HelpModalProps> = ({ isOpen, onClose }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center p-4">
      {/* Backdrop */}
      <div 
        className="absolute inset-0 bg-black/80 backdrop-blur-sm cursor-pointer"
        onClick={onClose}
      />
      
      {/* Modal */}
      <div className="relative bg-black/90 border border-white/10 rounded-xl max-w-2xl w-full max-h-[80vh] overflow-hidden">
        <div className="flex items-center justify-between p-6 border-b border-white/10">
          <h2 className="text-xl font-semibold text-white">How to Use the Globe</h2>
          <button
            onClick={onClose}
            className="text-gray-400 hover:text-white transition-colors"
          >
            <svg className="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>
        
        <div className="p-6 space-y-6 overflow-y-auto max-h-[calc(80vh-5rem)]">
          <div className="space-y-4">
            <section>
              <h3 className="text-lg font-medium text-white mb-2">Getting Started</h3>
              <p className="text-gray-300">
                Click on countries on the globe to mark them as visited or unvisited. The globe can be rotated, zoomed, and panned to explore different regions.
              </p>
            </section>

            <section>
              <h3 className="text-lg font-medium text-white mb-2">Tracking Your Progress</h3>
              <p className="text-gray-300">
                The status card at the bottom shows your progress and allows you to:
              </p>
              <ul className="list-disc list-inside mt-2 space-y-1 text-gray-300">
                <li>View the total number of countries visited</li>
                <li>Search for specific countries</li>
                <li>Filter countries by visited/unvisited status</li>
                <li>Click countries directly from the list</li>
              </ul>
            </section>

            <section>
              <h3 className="text-lg font-medium text-white mb-2">Action Buttons</h3>
              <ul className="space-y-2 text-gray-300">
                <li className="flex items-center space-x-2">
                  <span className="text-blue-400">Save</span>
                  <span>- Save your progress (requires account)</span>
                </li>
                <li className="flex items-center space-x-2">
                  <span className="text-blue-400">Reset</span>
                  <span>- Revert to your last saved state</span>
                </li>
                <li className="flex items-center space-x-2">
                  <span className="text-blue-400">Clear</span>
                  <span>- Remove all selected countries</span>
                </li>
                <li className="flex items-center space-x-2">
                  <span className="text-blue-400">Share</span>
                  <span>- Generate and download a shareable image</span>
                </li>
              </ul>
            </section>

            <section>
              <h3 className="text-lg font-medium text-white mb-2">Need More Help?</h3>
              <p className="text-gray-300">
                If you have any questions or need assistance, please contact us at <a href='mailto:cole@visited.ai' className='text-blue-400'>cole@visited.ai</a>
              </p>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HelpModal; 