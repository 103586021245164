import React from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { usePageTitle } from '../hooks/usePageTitle';

const Home: React.FC = () => {
  const { user } = useAuth();
  usePageTitle('Home');

  return (
    <div className="min-h-screen pt-32 sm:pt-40 pb-12 px-4 relative overflow-hidden">
      {/* Background Patterns */}
      <div className="absolute inset-0 overflow-hidden pointer-events-none">
        {/* Grid Lines */}
        <div className="absolute inset-0 bg-[linear-gradient(to_right,#ffffff05_1px,transparent_1px),linear-gradient(to_bottom,#ffffff05_1px,transparent_1px)] bg-[size:4rem_4rem]" />
        {/* Diagonal Lines */}
        <div className="absolute inset-0 bg-[linear-gradient(45deg,#ffffff03_1px,transparent_1px)] bg-[size:2rem_2rem]" />
        {/* Gradient Orbs */}
        <div className="absolute -top-40 -left-40 w-80 h-80 bg-blue-500/20 rounded-full blur-3xl" />
        <div className="absolute -bottom-40 -right-40 w-80 h-80 bg-green-500/20 rounded-full blur-3xl" />
      </div>

      <div className="max-w-7xl mx-auto relative">
        {/* Hero Section */}
        <div className="text-center mb-16">
          <h1 className="text-4xl sm:text-6xl font-bold mb-8 sm:mb-12 px-4">
            <span className="bg-gradient-to-r from-blue-400 via-green-400 to-blue-600 bg-clip-text text-transparent">
              Track Your World Travels
            </span>
          </h1>
          <p className="text-gray-400 text-lg sm:text-xl max-w-2xl mx-auto mb-8">
            Visualize your travels on an interactive 3D globe. Mark countries you've visited and watch your globe fill up. Coming soon: AI-powered travel recommendations!
          </p>
          <div className="flex flex-col sm:flex-row items-center justify-center gap-4">
            <Link
              to="/globe"
              className="gradient-border-button inline-flex items-center justify-center font-semibold group px-8 py-3"
            >
              Go to Globe
              <svg 
                xmlns="http://www.w3.org/2000/svg" 
                className="h-5 w-5 ml-2 transform transition-transform group-hover:translate-x-1" 
                viewBox="0 0 20 20" 
                fill="currentColor"
              >
                <path 
                  fillRule="evenodd" 
                  d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" 
                  clipRule="evenodd" 
                />
              </svg>
            </Link>
            {!user && (
              <Link
                to="/signup"
                className="px-8 py-3 bg-gradient-to-r from-blue-500 to-green-600 hover:from-blue-600 hover:to-green-700 rounded-lg text-white font-semibold transition-all duration-200 shadow-lg hover:shadow-xl"
              >
                Create Account
              </Link>
            )}
          </div>
        </div>

        {/* Globe Preview */}
        <div className="relative w-full max-w-4xl mx-auto aspect-[4/3] mb-16 rounded-2xl overflow-hidden group">
          <div className="absolute inset-0 bg-gradient-to-b from-black/0 to-black/80 z-10" />
          <div className="absolute inset-0 bg-[radial-gradient(circle_at_center,rgba(0,0,0,0)_0%,#0A0A0A_100%)]" />
          <div className="absolute inset-0 flex items-center justify-center">
            <div className="w-full h-full bg-[url('/public/globe-preview.png')] bg-center bg-cover opacity-80 group-hover:opacity-100 transition-opacity duration-300" />
          </div>
          <div className="absolute bottom-0 left-0 right-0 p-6 z-20">
            <div className="bg-black/40 backdrop-blur-sm rounded-lg p-4 border border-white/10">
              <p className="text-white/90 text-sm">
                Interactive 3D globe with real-time country selection and beautiful visualizations
              </p>
            </div>
          </div>
        </div>

        {/* Features Grid */}
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 mb-16">
          <div className="p-6 rounded-xl bg-black/30 backdrop-blur-sm border border-white/10 hover:border-blue-500/30 transition-colors group">
            <div className="w-12 h-12 rounded-lg bg-gradient-to-br from-blue-500/20 to-green-500/20 flex items-center justify-center mb-4">
              <svg className="w-6 h-6 text-blue-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3.055 11H5a2 2 0 012 2v1a2 2 0 002 2 2 2 0 012 2v2.945M8 3.935V5.5A2.5 2.5 0 0010.5 8h.5a2 2 0 012 2 2 2 0 104 0 2 2 0 012-2h1.064M15 20.488V18a2 2 0 012-2h3.064M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
              </svg>
            </div>
            <h3 className="text-xl font-semibold mb-3 text-white">Interactive</h3>
            <p className="text-gray-400">Rotate, zoom, and explore countries in stunning 3D visualization with smooth animations and real-time interactions.</p>
          </div>

          <div className="p-6 rounded-xl bg-black/30 backdrop-blur-sm border border-white/10 hover:border-green-500/30 transition-colors group">
            <div className="w-12 h-12 rounded-lg bg-gradient-to-br from-green-500/20 to-blue-500/20 flex items-center justify-center mb-4">
              <svg className="w-6 h-6 text-green-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 19v-6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2a2 2 0 002-2zm0 0V9a2 2 0 012-2h2a2 2 0 012 2v10m-6 0a2 2 0 002 2h2a2 2 0 002-2m0 0V5a2 2 0 012-2h2a2 2 0 012 2v14a2 2 0 01-2 2h-2a2 2 0 01-2-2z" />
              </svg>
            </div>
            <h3 className="text-xl font-semibold mb-3 text-white">Track</h3>
            <p className="text-gray-400">Return after your travels and add your newly visited countries.</p>
          </div>

          <div className="p-6 rounded-xl bg-black/30 backdrop-blur-sm border border-white/10 hover:border-purple-500/30 transition-colors group">
            <div className="w-12 h-12 rounded-lg bg-gradient-to-br from-purple-500/20 to-pink-500/20 flex items-center justify-center mb-4">
              <svg className="w-6 h-6 text-purple-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 10V3L4 14h7v7l9-11h-7z" />
              </svg>
            </div>
            <h3 className="text-xl font-semibold mb-3 text-white">AI Trip Recommendations</h3>
            <p className="text-gray-400">Get personalized travel recommendations based on your visited countries. Coming April 2025!</p>
            <div className="mt-3 inline-flex items-center px-2 py-1 rounded-full bg-purple-500/10 border border-purple-500/20">
              <span className="text-purple-400 text-xs font-medium">Coming Soon</span>
            </div>
          </div>

          <div className="p-6 rounded-xl bg-black/30 backdrop-blur-sm border border-white/10 hover:border-yellow-500/30 transition-colors group">
            <div className="w-12 h-12 rounded-lg bg-gradient-to-br from-yellow-500/20 to-orange-500/20 flex items-center justify-center mb-4">
              <svg className="w-6 h-6 text-yellow-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8.684 13.342C8.886 12.938 9 12.482 9 12c0-.482-.114-.938-.316-1.342m0 2.684a3 3 0 110-2.684m0 2.684l6.632 3.316m-6.632-6l6.632-3.316m0 0a3 3 0 105.367-2.684 3 3 0 00-5.367 2.684zm0 9.316a3 3 0 105.368 2.684 3 3 0 00-5.368-2.684z" />
              </svg>
            </div>
            <h3 className="text-xl font-semibold mb-3 text-white">Share</h3>
            <p className="text-gray-400">Share your travel progress with friends, through 3D GIFs, or 2D JPGs.</p>
          </div>

          <div className="p-6 rounded-xl bg-black/30 backdrop-blur-sm border border-white/10 hover:border-red-500/30 transition-colors group">
            <div className="w-12 h-12 rounded-lg bg-gradient-to-br from-red-500/20 to-pink-500/20 flex items-center justify-center mb-4">
              <svg className="w-6 h-6 text-red-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z" />
              </svg>
            </div>
            <h3 className="text-xl font-semibold mb-3 text-white">Secure</h3>
            <p className="text-gray-400">Your data is securely stored and private. Also, easily export your data to JSON.</p>
          </div>

          <div className="p-6 rounded-xl bg-black/30 backdrop-blur-sm border border-white/10 hover:border-cyan-500/30 transition-colors group">
            <div className="w-12 h-12 rounded-lg bg-gradient-to-br from-cyan-500/20 to-blue-500/20 flex items-center justify-center mb-4">
              <svg className="w-6 h-6 text-cyan-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 10V3L4 14h7v7l9-11h-7z" />
              </svg>
            </div>
            <h3 className="text-xl font-semibold mb-3 text-white">Fast</h3>
            <p className="text-gray-400">Optimized performance ensures smooth interactions and instant updates to your travel map.</p>
          </div>
        </div>

        {/* CTA Section */}
        {!user && (
          <div className="text-center">
            <h2 className="text-3xl font-bold mb-6">
              <span className="bg-gradient-to-r from-blue-400 to-green-600 bg-clip-text text-transparent">
                Ready to Get Started?
              </span>
            </h2>
            <p className="text-gray-400 text-lg mb-8 max-w-2xl mx-auto">
              Join thousands of travelers already tracking their visited countries. Create your account now!
            </p>
            <Link
              to="/signup"
              className="gradient-border-button inline-flex items-center justify-center font-semibold group px-8 py-3"
            >
              Create Account
            </Link>
          </div>
        )}
      </div>
    </div>
  );
};

export default Home; 