import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import AuthNav from './AuthNav';

const Navbar: React.FC = () => {
  const location = useLocation();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const isActive = (path: string) => location.pathname === path;

  const navLinkClasses = (path: string) =>
    `px-3 sm:px-4 py-2 rounded-lg transition-all duration-200 ${
      isActive(path)
        ? 'gradient-border-button'
        : 'text-gray-400 hover:text-white hover:bg-white/5'
    }`;

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <nav className="w-full max-w-7xl bg-black/30 backdrop-blur-md border border-white/10 rounded-xl sm:rounded-2xl">
      <div className="px-2 sm:px-8 py-3 sm:py-4">
        <div className="flex items-center justify-between">
          <div className="flex items-center space-x-2 sm:space-x-8">
            <Link 
              to="/" 
              className="flex items-center space-x-2"
            >
              <span className="text-base sm:text-xl font-bold bg-gradient-to-r from-blue-400 to-green-600 bg-clip-text text-transparent">
                my visited countries
              </span>
            </Link>
            
            {/* Desktop Navigation */}
            <div className="hidden sm:flex items-center space-x-2">
              <Link to="/globe" className={navLinkClasses('/globe')}>
                Globe
              </Link>
              <Link to="/recommendations" className={navLinkClasses('/recommendations')}>
                <div className="flex items-center">
                  <svg className="w-5 h-5 mr-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 10V3L4 14h7v7l9-11h-7z" />
                  </svg>
                  AI Trip Recommendations
                </div>
              </Link>
            </div>
          </div>

          {/* Desktop Auth */}
          <div className="hidden sm:block">
            <AuthNav onAction={() => setIsMenuOpen(false)} />
          </div>

          {/* Mobile Menu Button */}
          <button
            onClick={toggleMenu}
            className="sm:hidden p-2 rounded-lg hover:bg-white/5"
          >
            <svg
              className="w-6 h-6 text-white"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              {isMenuOpen ? (
                <path d="M6 18L18 6M6 6l12 12" />
              ) : (
                <path d="M4 6h16M4 12h16M4 18h16" />
              )}
            </svg>
          </button>
        </div>

        {/* Mobile Menu */}
        <div className={`sm:hidden ${isMenuOpen ? 'block' : 'hidden'}`}>
          <div className="pt-4 pb-2 space-y-1">
            <Link
              to="/globe"
              className={`block ${navLinkClasses('/globe')}`}
              onClick={() => setIsMenuOpen(false)}
            >
              Globe
            </Link>
            <Link
              to="/recommendations"
              className={`block ${navLinkClasses('/recommendations')}`}
              onClick={() => setIsMenuOpen(false)}
            >
              <div className="flex items-center">
                <svg className="w-5 h-5 mr-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 10V3L4 14h7v7l9-11h-7z" />
                </svg>
                AI Trip Recommendations
              </div>
            </Link>
            <div className="pt-2">
              <AuthNav onAction={() => setIsMenuOpen(false)} />
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar; 