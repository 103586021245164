import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { usePageTitle } from '../hooks/usePageTitle';
import { supabase } from '../services/supabase';
import { GeoJSONData } from '../types/geojson';

interface Country {
  id: string;
  code: string;
  name: string;
  visited: boolean;
}

const Profile: React.FC = () => {
  usePageTitle('Profile');
  const navigate = useNavigate();
  const { user, logout, updateUserProfile } = useAuth();
  const [countries, setCountries] = useState<Country[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [isEditingName, setIsEditingName] = useState(false);
  const [fullName, setFullName] = useState(user?.user_metadata.full_name || '');
  const [isUpdatingName, setIsUpdatingName] = useState(false);
  const [visitedCountriesData, setVisitedCountriesData] = useState<{ country: string; visited_at: string }[]>([]);

  useEffect(() => {
    if (!user) {
      navigate('/login');
      return;
    }

    setFullName(user.user_metadata.full_name || '');

    const fetchData = async () => {
      try {
        // Fetch user's visited countries with timestamps
        const { data: visitedCountries, error: countriesError } = await supabase
          .from('user_countries')
          .select('country, visited_at')
          .eq('user_id', user.id)
          .order('visited_at', { ascending: false });

        if (countriesError) throw countriesError;
        setVisitedCountriesData(visitedCountries || []);

        // Fetch all countries from GeoJSON
        const response = await fetch('/assets/geojson/ne_110m_admin_0_countries.geojson');
        const data: GeoJSONData = await response.json();

        // Create country objects and mark visited ones
        const visitedSet = new Set(visitedCountries?.map(c => c.country) || []);
        const allCountries = data.features
          .filter(d => d.properties.ISO_A2 !== 'AQ') // Filter out Antarctica
          .map(feature => ({
            id: feature.properties.ISO_A2,
            code: feature.properties.ISO_A2,
            name: feature.properties.ADMIN,
            visited: visitedSet.has(feature.properties.ADMIN)
          }))
          .sort((a, b) => a.name.localeCompare(b.name));

        setCountries(allCountries);
      } catch (err) {
        console.error('Error loading profile:', err);
        setError('Error loading profile');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [user, navigate]);

  const handleSignOut = async () => {
    try {
      await logout();
      navigate('/login');
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  const handleUpdateName = async () => {
    if (!fullName.trim()) return;
    
    setIsUpdatingName(true);
    try {
      await updateUserProfile({ full_name: fullName.trim() });
      setIsEditingName(false);
    } catch (error) {
      console.error('Error updating name:', error);
      setError('Failed to update name');
    } finally {
      setIsUpdatingName(false);
    }
  };

  const handleExportData = () => {
    const exportData = {
      user: {
        name: user?.user_metadata.full_name || 'User',
        email: user?.email
      },
      visitedCountries: visitedCountriesData.map(({ country, visited_at }) => ({
        name: country,
        visited_at: visited_at
      })),
      exportedAt: new Date().toISOString()
    };

    const blob = new Blob([JSON.stringify(exportData, null, 2)], { type: 'application/json' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `visited-countries-${new Date().toISOString().split('T')[0]}.json`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };

  if (loading) {
    return (
      <div className="min-h-screen bg-[#0A0A0A] flex items-center justify-center">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="min-h-screen bg-[#0A0A0A] flex items-center justify-center">
        <div className="text-red-500">{error}</div>
      </div>
    );
  }

  const visitedCountries = countries.filter(c => c.visited);
  const unvisitedCountries = countries.filter(c => !c.visited);

  return (
    <div className="min-h-screen bg-[#0A0A0A] text-white p-4 sm:p-8 pt-24 sm:pt-28">
      <div className="max-w-4xl mx-auto">
        {/* Profile Header */}
        <div className="bg-black/30 backdrop-blur-md border border-white/10 rounded-xl p-6 mb-6">
          <div className="flex flex-col sm:flex-row items-start sm:items-center justify-between gap-4">
            <div className="flex-1">
              {isEditingName ? (
                <div className="flex items-center gap-2">
                  <input
                    type="text"
                    value={fullName}
                    onChange={(e) => setFullName(e.target.value)}
                    className="bg-black/20 border border-white/10 rounded-lg px-3 py-1.5 text-white focus:outline-none focus:border-blue-500"
                    placeholder="Enter your name"
                  />
                  <button
                    onClick={handleUpdateName}
                    disabled={isUpdatingName || !fullName.trim()}
                    className="px-3 py-1.5 bg-blue-500 text-white rounded-lg hover:bg-blue-600 disabled:opacity-50 disabled:cursor-not-allowed transition-colors"
                  >
                    {isUpdatingName ? 'Saving...' : 'Save'}
                  </button>
                  <button
                    onClick={() => {
                      setIsEditingName(false);
                      setFullName(user?.user_metadata.full_name || '');
                    }}
                    className="px-3 py-1.5 bg-white/10 text-white rounded-lg hover:bg-white/20 transition-colors"
                  >
                    Cancel
                  </button>
                </div>
              ) : (
                <div className="flex items-center gap-3">
                  <h1 className="text-2xl sm:text-3xl font-bold bg-gradient-to-r from-blue-400 to-green-600 bg-clip-text text-transparent">
                    {user?.user_metadata.full_name || 'User'}
                  </h1>
                  <button
                    onClick={() => setIsEditingName(true)}
                    className="p-1.5 text-gray-400 hover:text-white rounded-lg hover:bg-white/5 transition-colors"
                    title="Edit name"
                  >
                    <svg className="w-5 h-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z" />
                    </svg>
                  </button>
                </div>
              )}
              <p className="text-gray-400 mt-1">{user?.email}</p>
            </div>
            <div className="flex items-center gap-2">
              <button
                onClick={handleExportData}
                className="px-4 py-2 bg-blue-500/20 text-blue-400 rounded-lg hover:bg-blue-500/30 transition-colors flex items-center gap-2"
                title="Export data"
              >
                <svg className="w-5 h-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4" />
                </svg>
                Export
              </button>
              <button
                onClick={handleSignOut}
                className="px-4 py-2 bg-red-500/20 text-red-400 rounded-lg hover:bg-red-500/30 transition-colors"
              >
                Sign Out
              </button>
            </div>
          </div>
        </div>

        {/* Statistics */}
        <div className="grid grid-cols-1 sm:grid-cols-3 gap-4 mb-6">
          <div className="bg-black/30 backdrop-blur-md border border-white/10 rounded-xl p-4">
            <h3 className="text-gray-400 text-sm">Total Countries</h3>
            <p className="text-2xl font-bold">{countries.length}</p>
          </div>
          <div className="bg-black/30 backdrop-blur-md border border-white/10 rounded-xl p-4">
            <h3 className="text-gray-400 text-sm">Visited Countries</h3>
            <p className="text-2xl font-bold text-green-500">{visitedCountries.length}</p>
          </div>
          <div className="bg-black/30 backdrop-blur-md border border-white/10 rounded-xl p-4">
            <h3 className="text-gray-400 text-sm">Countries to Visit</h3>
            <p className="text-2xl font-bold text-blue-500">{unvisitedCountries.length}</p>
          </div>
        </div>

        {/* Countries Lists */}
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
          {/* Visited Countries */}
          <div className="bg-black/30 backdrop-blur-md border border-white/10 rounded-xl p-6">
            <h2 className="text-xl font-bold mb-4 text-green-500">Visited Countries</h2>
            <div className="space-y-2 max-h-[400px] overflow-y-auto">
              {visitedCountries.map(country => (
                <div
                  key={country.id}
                  className="flex items-center justify-between p-2 rounded-lg bg-white/5 hover:bg-white/10"
                >
                  <span>{country.name}</span>
                  <span className="text-green-500">✓</span>
                </div>
              ))}
            </div>
          </div>

          {/* Countries to Visit */}
          <div className="bg-black/30 backdrop-blur-md border border-white/10 rounded-xl p-6">
            <h2 className="text-xl font-bold mb-4 text-blue-500">Countries to Visit</h2>
            <div className="space-y-2 max-h-[400px] overflow-y-auto">
              {unvisitedCountries.map(country => (
                <div
                  key={country.id}
                  className="flex items-center justify-between p-2 rounded-lg bg-white/5 hover:bg-white/10"
                >
                  <span>{country.name}</span>
                  <span className="text-gray-500">○</span>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile; 