import { useEffect, useRef, useState, useCallback } from 'react';
// @ts-ignore
import Globe, { GlobeInstance } from 'globe.gl';
import { createStarfield } from '../utils/getStarfield';
import * as THREE from 'three';
import { useAuth } from '../contexts/AuthContext';
import { supabase } from '../services/supabase';
import ShareModal from './ShareModal';
import HelpModal from './HelpModal';
import { GeoJSONFeature, GeoJSONData } from '../types/geojson';
import StatusCard from './StatusCard';
import Toast from './Toast';

interface ToastState {
  message: string;
  type: 'success' | 'error' | 'info';
  show: boolean;
}

interface GlobeVisualizationProps {
  allCountries: string[];
  visitedCountries: Set<string>;
  onCountryToggle: (country: string) => void;
  geoJsonData: GeoJSONData;
  onSave: () => Promise<void>;
  onReset: () => void;
  onClear: () => void;
  hasPendingChanges: boolean;
}

const GlobeVisualization: React.FC<GlobeVisualizationProps> = ({ 
  allCountries, 
  visitedCountries,
  onCountryToggle,
  geoJsonData,
  onSave,
  onReset,
  onClear,
  hasPendingChanges
}) => {
  console.log('GlobeVisualization rendering, countries:', allCountries.length, 'visited:', visitedCountries.size);
  
  const containerRef = useRef<HTMLDivElement>(null);
  const globeRef = useRef<GlobeInstance | null>(null);
  const autoRotationTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const { user } = useAuth();
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [showShareModal, setShowShareModal] = useState(false);
  const [showHelp, setShowHelp] = useState(false);
  const [toast, setToast] = useState<ToastState>({
    message: '',
    type: 'info',
    show: false
  });

  // Create a memoized handler for updating visited countries
  const handleCountryClick = useCallback((polygonOrCountry: any) => {
    let country: string;
    
    // Handle both polygon clicks and dropdown clicks
    if (typeof polygonOrCountry === 'string') {
      country = polygonOrCountry;
    } else if (polygonOrCountry?.properties?.ADMIN) {
      country = polygonOrCountry.properties.ADMIN;
    } else {
      return;
    }

    // Only proceed if the country exists in our valid countries list
    if (!allCountries.includes(country)) {
      return;
    }

    onCountryToggle(country);
  }, [allCountries, onCountryToggle]);

  // Handle save action
  const handleSave = async () => {
    if (!user || !hasPendingChanges) return;
    setIsSaving(true);
    try {
      await onSave();
      setToast({
        message: 'Changes saved successfully',
        type: 'success',
        show: true
      });
    } catch (error) {
      console.error('Error saving changes:', error);
      setToast({
        message: 'Failed to save changes',
        type: 'error',
        show: true
      });
    } finally {
      setIsSaving(false);
    }
  };

  // Handle clear action
  const handleClear = () => {
    onClear();
    setToast({
      message: 'All countries cleared',
      type: 'info',
      show: true
    });
  };

  // Initialize the globe only once
  useEffect(() => {
    console.log('GlobeVisualization useEffect triggered, geoJsonData:', !!geoJsonData);
    if (!containerRef.current) return;

    // Initialize globe
    const globe = new Globe(containerRef.current, {
      waitForGlobeReady: true,
      animateIn: false
    })
      .globeImageUrl('//unpkg.com/three-globe/example/img/earth-dark.jpg')
      .backgroundColor('#000000')
      .showAtmosphere(true)
      .atmosphereColor('white')
      .atmosphereAltitude(0.1)
      .polygonAltitude(0.01)
      .polygonCapColor(feat => {
        const country = (feat as GeoJSONFeature).properties.ADMIN;
        return visitedCountries.has(country) 
          ? 'rgba(22, 163, 74, 0.8)'
          : 'rgba(68, 68, 68, 0.1)';
      })
      .polygonSideColor(() => '#60A5FA')
      .polygonStrokeColor(() => '#60A5FA')
      // @ts-ignore
      .polygonLabel(({ properties: d }: { properties: GeoJSONFeature['properties'] }) => {
        if (!d?.ADMIN) return '';
        return `
          <div class="bg-black/80 backdrop-blur-sm px-2 py-1 rounded text-white text-sm">
            ${d.ADMIN}
          </div>
        `;
      })
      // @ts-ignore
      .onPolygonClick((polygon, event) => {
        event.stopPropagation();
        handleCountryClick(polygon);
      })
      .polygonsTransitionDuration(300)
      .width(window.innerWidth)
      .height(window.innerHeight);

    // Set zoom limits
    globe.controls().minDistance = 150;
    globe.controls().maxDistance = 450;
    globe.controls().minPolarAngle = Math.PI * 0.1;
    globe.controls().maxPolarAngle = Math.PI * 0.9;

    // Add starfield as a custom layer
    const starfield = createStarfield()(globe.scene());

    // Add auto-rotation with delayed restart
    let isAutoRotating = true;
    globe.controls().autoRotate = true;
    globe.controls().autoRotateSpeed = 0.3;

    const startAutoRotation = () => {
      isAutoRotating = true;
      globe.controls().autoRotate = true;
    };

    const stopAutoRotation = () => {
      isAutoRotating = false;
      globe.controls().autoRotate = false;
      
      // Clear any existing timeout
      if (autoRotationTimeoutRef.current) {
        clearTimeout(autoRotationTimeoutRef.current);
      }
      
      // Set new timeout to restart rotation after 10 seconds
      autoRotationTimeoutRef.current = setTimeout(startAutoRotation, 10000);
    };

    // Add controls event listeners
    globe.controls().addEventListener('start', stopAutoRotation);

    globeRef.current = globe;

    // Load the GeoJSON data
    const filteredData = geoJsonData.features.filter(d => d.properties.ISO_A2 !== 'AQ');
    globe.polygonsData(filteredData);
    console.log('Globe initialized with GeoJSON data');

    // Handle window resize
    const handleResize = () => {
      globe
        .width(window.innerWidth)
        .height(window.innerHeight);
    };
    window.addEventListener('resize', handleResize);

    // Cleanup
    return () => {
      console.log('GlobeVisualization cleanup');
      window.removeEventListener('resize', handleResize);
      if (autoRotationTimeoutRef.current) {
        clearTimeout(autoRotationTimeoutRef.current);
      }
      starfield.dispose();
      if (globeRef.current) {
        globeRef.current = null;
      }
    };
  }, [geoJsonData]); // Add geoJsonData as dependency

  // Update country colors when visitedCountries changes
  useEffect(() => {
    if (!globeRef.current) return;

    globeRef.current.polygonCapColor((feature) => {
      const country = (feature as GeoJSONFeature).properties.ADMIN;
      return visitedCountries.has(country) 
        ? 'rgba(22, 163, 74, 0.8)'
        : 'rgba(68, 68, 68, 0.1)';
    });
  }, [visitedCountries]);

  return (
    <div className="relative w-full h-screen bg-black overflow-hidden">
      <div ref={containerRef} className="absolute inset-0" />
      
      {/* Bottom UI Container */}
      <div className="fixed bottom-0 left-0 right-0 p-4 flex flex-col items-center gap-4 z-50">
        {/* Status Card */}
        <div className="w-[320px]">
          <StatusCard 
            visitedCount={visitedCountries.size} 
            totalCount={allCountries.length}
            visitedCountries={visitedCountries}
            onCountryClick={handleCountryClick}
            isAuthenticated={!!user}
            allCountries={allCountries}
            setAllCountries={() => {}} // Empty function since we don't need to set countries anymore
          />
        </div>
        
        {/* Action Buttons Container */}
        <div className="w-[320px]">
          <div className="grid grid-cols-3 sm:grid-cols-5 gap-2">
            <div className="group relative w-full">
              <button
                onClick={handleSave}
                disabled={!user || !hasPendingChanges || isSaving}
                className={`w-full p-2.5 bg-black/30 backdrop-blur-sm border border-white/10 rounded-lg text-sm font-medium transition-all duration-200 flex items-center justify-center shadow-lg ${
                  !user || !hasPendingChanges || isSaving ? 'opacity-50 cursor-not-allowed' : 'hover:bg-white/5'
                }`}
              >
                {isSaving ? (
                  <svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                  </svg>
                ) : (
                  <svg className="w-5 h-5" viewBox="0 0 24 24" fill="none">
                    <path d="M19 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11l5 5v11a2 2 0 0 1-2 2z" className="stroke-[url(#save-gradient)]" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    <defs>
                      <linearGradient id="save-gradient" x1="0%" y1="0%" x2="100%" y2="0%">
                        <stop offset="0%" stopColor="#60A5FA" />
                        <stop offset="100%" stopColor="#22C55E" />
                      </linearGradient>
                    </defs>
                  </svg>
                )}
              </button>
              <div className="absolute bottom-full left-1/2 -translate-x-1/2 mb-2 px-2 py-1 bg-black/80 text-white text-sm rounded pointer-events-none transition-opacity duration-200 opacity-0 group-hover:opacity-100">
                {isSaving ? 'Saving...' : 'Save'}
                <div className="absolute top-full left-1/2 -translate-x-1/2 -mt-px border-4 border-transparent border-t-black/80"></div>
              </div>
            </div>

            <div className="group relative w-full">
              <button
                onClick={onReset}
                disabled={!user || !hasPendingChanges}
                className={`w-full p-2.5 bg-black/30 backdrop-blur-sm border border-white/10 rounded-lg text-sm font-medium transition-all duration-200 flex items-center justify-center shadow-lg ${
                  !user || !hasPendingChanges ? 'opacity-50 cursor-not-allowed' : 'hover:bg-white/5'
                }`}
              >
                <svg className="w-5 h-5" viewBox="0 0 24 24" fill="none">
                  <path d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" className="stroke-[url(#reset-gradient)]" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                  <defs>
                    <linearGradient id="reset-gradient" x1="0%" y1="0%" x2="100%" y2="0%">
                      <stop offset="0%" stopColor="#60A5FA" />
                      <stop offset="100%" stopColor="#22C55E" />
                    </linearGradient>
                  </defs>
                </svg>
              </button>
              <div className="absolute bottom-full left-1/2 -translate-x-1/2 mb-2 px-2 py-1 bg-black/80 text-white text-sm rounded pointer-events-none transition-opacity duration-200 opacity-0 group-hover:opacity-100">
                Reset
                <div className="absolute top-full left-1/2 -translate-x-1/2 -mt-px border-4 border-transparent border-t-black/80"></div>
              </div>
            </div>

            <div className="group relative w-full">
              <button
                onClick={handleClear}
                disabled={!hasPendingChanges && visitedCountries.size === 0}
                className={`w-full p-2.5 bg-black/30 backdrop-blur-sm border border-white/10 rounded-lg text-sm font-medium transition-all duration-200 flex items-center justify-center shadow-lg ${
                  !hasPendingChanges && visitedCountries.size === 0 ? 'opacity-50 cursor-not-allowed' : 'hover:bg-white/5'
                }`}
              >
                <svg className="w-5 h-5" viewBox="0 0 24 24" fill="none">
                  <path d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" className="stroke-[url(#clear-gradient)]" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                  <defs>
                    <linearGradient id="clear-gradient" x1="0%" y1="0%" x2="100%" y2="0%">
                      <stop offset="0%" stopColor="#60A5FA" />
                      <stop offset="100%" stopColor="#22C55E" />
                    </linearGradient>
                  </defs>
                </svg>
              </button>
              <div className="absolute bottom-full left-1/2 -translate-x-1/2 mb-2 px-2 py-1 bg-black/80 text-white text-sm rounded pointer-events-none transition-opacity duration-200 opacity-0 group-hover:opacity-100">
                Clear
                <div className="absolute top-full left-1/2 -translate-x-1/2 -mt-px border-4 border-transparent border-t-black/80"></div>
              </div>
            </div>
            
            <div className="group relative w-full">
              <button
                onClick={() => setShowShareModal(true)}
                className="w-full p-2.5 bg-black/30 backdrop-blur-sm border border-white/10 rounded-lg text-sm font-medium transition-all duration-200 flex items-center justify-center shadow-lg hover:bg-white/5"
              >
                <svg className="w-5 h-5" viewBox="0 0 24 24" fill="none">
                  <path d="M8.684 13.342C8.886 12.938 9 12.482 9 12c0-.482-.114-.938-.316-1.342m0 2.684a3 3 0 110-2.684m0 2.684l6.632 3.316m-6.632-6l6.632-3.316m0 0a3 3 0 105.367-2.684 3 3 0 00-5.367 2.684zm0 9.316a3 3 0 105.368 2.684 3 3 0 00-5.368-2.684z" className="stroke-[url(#share-gradient)]" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                  <defs>
                    <linearGradient id="share-gradient" x1="0%" y1="0%" x2="100%" y2="0%">
                      <stop offset="0%" stopColor="#60A5FA" />
                      <stop offset="100%" stopColor="#22C55E" />
                    </linearGradient>
                  </defs>
                </svg>
              </button>
              <div className="absolute bottom-full left-1/2 -translate-x-1/2 mb-2 px-2 py-1 bg-black/80 text-white text-sm rounded pointer-events-none transition-opacity duration-200 opacity-0 group-hover:opacity-100">
                Share
                <div className="absolute top-full left-1/2 -translate-x-1/2 -mt-px border-4 border-transparent border-t-black/80"></div>
              </div>
            </div>

            <div className="group relative w-full">
              <button
                onClick={() => setShowHelp(true)}
                className="w-full p-2.5 bg-black/30 backdrop-blur-sm border border-white/10 rounded-lg text-sm font-medium transition-all duration-200 flex items-center justify-center shadow-lg hover:bg-white/5"
              >
                <svg className="w-5 h-5" viewBox="0 0 24 24" fill="none">
                  <path d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" className="stroke-[url(#help-gradient)]" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                  <defs>
                    <linearGradient id="help-gradient" x1="0%" y1="0%" x2="100%" y2="0%">
                      <stop offset="0%" stopColor="#60A5FA" />
                      <stop offset="100%" stopColor="#22C55E" />
                    </linearGradient>
                  </defs>
                </svg>
              </button>
              <div className="absolute bottom-full left-1/2 -translate-x-1/2 mb-2 px-2 py-1 bg-black/80 text-white text-sm rounded pointer-events-none transition-opacity duration-200 opacity-0 group-hover:opacity-100">
                Help
                <div className="absolute top-full left-1/2 -translate-x-1/2 -mt-px border-4 border-transparent border-t-black/80"></div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {toast.show && (
        <Toast
          message={toast.message}
          type={toast.type}
          onClose={() => setToast(prev => ({ ...prev, show: false }))}
        />
      )}

      <ShareModal
        isOpen={showShareModal}
        onClose={() => setShowShareModal(false)}
        visitedCountries={visitedCountries}
        totalCountries={allCountries.length}
        globeRef={globeRef}
      />

      <HelpModal
        isOpen={showHelp}
        onClose={() => setShowHelp(false)}
      />
    </div>
  );
};

export default GlobeVisualization; 